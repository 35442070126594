import React, {useState} from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBInput, MDBRow} from 'mdb-react-ui-kit';
import instance from "../../utils/instance";
import {useNavigate} from "react-router-dom";
import {ErrorNotify} from "../../alerts";

function Index() {
    const [phone, setPhone] = useState('+998')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    async function login(e) {
        e.preventDefault()
        let res = await instance("/auth/login", "POST", {phone, password});
        if (res.data.ok) {
            localStorage.setItem("access_token", res.data.data.access_token);
            localStorage.setItem("refresh_token", res.data.data.refresh_token)
            navigate("/dashboard")
        } else {
            ErrorNotify(res.data.message)
        }
    }

    return (
        <MDBContainer fluid>
            <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                <MDBCol col='12'>
                    <form onSubmit={login}>
                        <MDBCard className='bg-dark text-white my-5 mx-auto'
                                 style={{borderRadius: '1rem', maxWidth: '400px'}}>
                            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                                <h2 className="fw-bold mb-2 text-uppercase">Tizimga kirish</h2>
                                <p className="text-white-50 mb-5">Iltimos, telefon va parolingizni kiriting!</p>
                                <MDBInput required={true} value={phone} onChange={(e) => setPhone(e.target.value)}
                                          style={{color: "white"}} wrapperClass='mb-4 mx-5 w-100'
                                          labelClass='text-white'
                                          label='Telefon' id='formControlLg' type='tel' size="lg"/>
                                <MDBInput required={true} value={password} onChange={(e) => setPassword(e.target.value)}
                                          style={{color: "white"}} wrapperClass='mb-4 mx-5 w-100'
                                          labelClass='text-white'
                                          label='Parol' id='formControlLg' type='password' size="lg"/>
                                <MDBBtn outline className='mx-2 px-5' color='white' size='lg'>
                                    TIZIMGA KIRISH
                                </MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </form>
                </MDBCol>
            </MDBRow>

        </MDBContainer>
    );
}

export default Index;