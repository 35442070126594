import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Login from "./components/pages/Login";
import {Toaster} from "react-hot-toast";
import Dashboard from "./components/pages/Dashboard";
import {useEffect, useState} from "react";
import axios from "axios";
import {BASE_URL} from "./components/utils/instance/Base";
import Settings from "./components/pages/Settings";
import Api from "./components/pages/Api";
import Social from "./components/pages/Social";
import Service from "./components/pages/Service";
import Payments from "./components/pages/Payments";

function App() {
    const [user, setUser] = useState(null)
    const navigate = useNavigate()
    const location = useLocation();
    const permissions = [
        {url: "/dashboard", roles: ["ROLE_USER", "ROLE_ADMIN", "ROLE_CREATOR"]}
    ];
    useEffect(() => {
        hasPermissions()
    }, [location.pathname]);

    function hasPermissions() {
        let count = 0;
        permissions.map((item, index) => {
            if (item.url === location.pathname) {
                count = count + 1;
            }
        });
        if (count === 1) {
            if (localStorage.getItem("access_token") !== null) {
                axios({
                    url: BASE_URL + "/auth/me",
                    method: "GET",
                    headers: {
                        Authorization: localStorage.getItem("access_token"),
                    },
                })
                    .then((res) => {
                        setUser(res.data.data)
                        let s = false;
                        permissions.map((item) => {
                            if (item.url === location.pathname) {
                                res.data.data.roles.map((i1) => {
                                    if (item.roles.includes(i1)) {
                                        s = true;
                                    }
                                });
                            }
                        });
                        if (!s) {
                            navigate("/");
                        }
                    })
                    .catch((err) => {
                        if (err.response.status === 401) {
                            axios({
                                url:
                                    BASE_URL +
                                    "/auth/refresh?auth=" +
                                    localStorage.getItem("refresh_token"),
                                method: "POST",
                            })
                                .then((res) => {
                                    localStorage.setItem("access_token", res.data.data);
                                    window.location.reload();
                                })
                                .catch((err) => {
                                    navigate("/");
                                    localStorage.clear();
                                });
                        }
                    });
            } else {
                navigate("/");
            }
        }
    }

    return (
        <div>
            <Toaster position="top-right"
                     reverseOrder={false}/>
            <Routes>
                <Route path={"/"} element={<Login/>}/>
                <Route path={"/dashboard"} element={<Dashboard/>}/>
                <Route path={"/settings/:id"} element={<Settings/>}/>
                <Route path={"/payments/:id"} element={<Payments/>}/>
                <Route path={"/settings/api/:id"} element={<Api/>}/>
                <Route path={"/settings/social/:id"} element={<Social/>}/>
                <Route path={"/settings/service/:id"} element={<Service/>}/>
            </Routes>
        </div>
    );
}

export default App;
