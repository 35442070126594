import React, {useEffect, useState} from 'react';
import Footer from "../../ui/Footer";
import instance from "../../utils/instance";
import {MDBBtn, MDBIcon, MDBTable, MDBTableBody, MDBTableHead} from 'mdb-react-ui-kit';
import {useNavigate} from "react-router-dom";

function Index(props) {
    const [bots, setBots] = useState([])
    const navigate = useNavigate()

    async function getMyBots() {
        let res = await instance("/smm/my-bots", "GET");
        if (res.data.ok) {
            setBots(res.data.data)
        }
    }

    useEffect(() => {
        getMyBots()
    }, []);
    return (
        <div>
            <MDBTable striped>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>#ID</th>
                        <th scope='col'>USERNAME</th>
                        <th scope='col'>SETTINGS</th>
                        <th scope='col'>PAYMENTS</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {bots?.map((item) => <tr key={item?.id}>
                        <td>{item?.id}</td>
                        <td>{item?.username}</td>
                        <td><MDBBtn onClick={() => navigate(`/settings/${item?.id}`)} color='dark'>
                            <MDBIcon fas icon="sliders-h"/>
                        </MDBBtn></td>
                        <td><MDBBtn onClick={() => navigate(`/payments/${item?.id}`)} color='dark'>
                            <MDBIcon far icon="credit-card"/>
                        </MDBBtn></td>
                    </tr>)}
                </MDBTableBody>
            </MDBTable>
            <Footer/>
        </div>
    );
}

export default Index;