import React from 'react';
import {MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon} from 'mdb-react-ui-kit';

export default function Footer() {
    return (
        <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
                <div className='me-5 d-none d-lg-block'>
                    <span>Ijtimoiy tarmoqlarda biz bilan bog'laning:</span>
                </div>

                <div>
                    <a href='https://t.me/BenefitGram' className='me-4 text-reset'>
                        <MDBIcon color='secondary' fab icon='telegram'/>
                    </a><a href='https://www.facebook.com/profile.php?id=61553363008214&mibextid=LQQJ4d'
                           className='me-4 text-reset'>
                    <MDBIcon color='secondary' fab icon='facebook-f'/>
                </a>
                    <a href='https://www.instagram.com/haqnazarov.uz/' className='me-4 text-reset'>
                        <MDBIcon color='secondary' fab icon='instagram'/>
                    </a>
                    <a href='https://www.youtube.com/@HAQNAZAROVSTUDIO' className='me-4 text-reset'>
                        <MDBIcon color='secondary' fab icon='youtube'/>
                    </a>
                    <a href='https://github.com/haqnazarov' className='me-4 text-reset'>
                        <MDBIcon color='secondary' fab icon='github'/>
                    </a>
                </div>
            </section>

            <section className=''>
                <MDBContainer className='text-center text-md-start mt-5'>
                    <MDBRow className='mt-3'>
                        <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>
                                <MDBIcon color='secondary' icon='gem' className='me-3'/>
                                BENEFITGRAM
                            </h6>
                            <p>
                                Dasturlash kodlarisiz bot yarating!
                            </p>
                        </MDBCol>

                        <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                            <h6 className='text-uppercase fw-bold mb-4'>ALOQA</h6>
                            <p>
                                <MDBIcon color='secondary' icon='home' className='me-2'/>
                                Uzbekistan, Tashkent, UZ
                            </p>
                            <p>
                                <MDBIcon color='secondary' icon='envelope' className='me-3'/>
                                contact@haqnazarov.uz
                            </p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>

            <div className='text-center p-4' style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
                © 2023 Copyright:
                <a className='text-reset fw-bold' href='https://haqnazarov.uz/'>
                    HAQNAZAROV.UZ
                </a>
            </div>
        </MDBFooter>
    );
}