import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {MDBBtn, MDBIcon, MDBInput, MDBBtnGroup} from "mdb-react-ui-kit";
import instance from "../../utils/instance";
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import {MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';
import {ErrorNotify, SuccessNotify} from "../../alerts";

function Index(props) {
    const navigate = useNavigate()
    const {id} = useParams()
    const [socials, setSocials] = useState([])
    const [name, setName] = useState('')
    const [basicModal, setBasicModal] = useState(false);
    const [currentSocial, setCurrentSocial] = useState(null)
    const toggleOpen = () => {
        setBasicModal(!basicModal)
        setName('')
    }

    async function getSocialList(id) {
        let res = await instance(`/smm/social/${id}`, "GET");
        if (res.data.ok) {
            setSocials(res.data.data)
        }
    }

    useEffect(() => {
        getSocialList(id)
    }, [id]);

    async function saveSocial() {
        if (currentSocial === null) {
            let axiosResponse = await instance("/smm/social", "POST", {botId: id, name});
            if (axiosResponse.data.ok) {
                SuccessNotify(axiosResponse.data.message)
                getSocialList(id)
            } else {
                ErrorNotify(axiosResponse.data.message)
            }
        } else {
            let axiosResponse = await instance(`/smm/social/${currentSocial.id}`, "PUT", {botId: id, name});
            if (axiosResponse.data.ok) {
                setCurrentSocial(null)
                SuccessNotify(axiosResponse.data.message)
                getSocialList(id)
            } else {
                ErrorNotify(axiosResponse.data.message)
            }
        }
        toggleOpen()
    }

    async function deleteSocial(s_id) {
        let response = await instance(`/smm/social/${s_id}`, "DELETE");
        if (response.data.ok) {
            SuccessNotify(response.data.message)
            getSocialList(id)
        } else {
            ErrorNotify(response.data.message)
        }
    }

    function editSocial(item) {
        setCurrentSocial(item)
        toggleOpen()
        setName(item.name)
    }

    return (
        <>
            <div style={{
                width: "100%",
                padding: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <h5 style={{cursor: "pointer"}} onClick={() => navigate(`/settings/${id}`)}><MDBIcon fas
                                                                                                     icon="chevron-left"/> ORQAGA
                </h5>
                <MDBBtn onClick={toggleOpen} className='me-1' color='secondary'>
                    TARMOQ QO'SHISH <MDBIcon fas icon="folder-plus"/>
                </MDBBtn>
            </div>

            <MDBTable striped>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>#</th>
                        <th scope='col'>NOMI</th>
                        <th scope='col'>AMALLAR</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {
                        socials?.map((item, index) => <tr>
                            <td>{index + 1}</td>
                            <td>{item?.name}</td>
                            <td><MDBBtnGroup shadow='0'>
                                <MDBBtn onClick={() => deleteSocial(item.id)} color='danger'>
                                    <MDBIcon fas icon="trash"/>
                                </MDBBtn>
                                <MDBBtn onClick={() => editSocial(item)} color='success'>
                                    <MDBIcon fas icon="pencil-alt"/>
                                </MDBBtn>
                            </MDBBtnGroup></td>
                        </tr>)
                    }
                </MDBTableBody>
            </MDBTable>
            <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Modal title</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput value={name} onChange={(e) => setName(e.target.value)}
                                      style={{marginBottom: 20}} label='TARMOQ NOMI' id='form1' type='text'/>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn onClick={saveSocial}>SAQLASH</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

export default Index;