import {createSlice} from "@reduxjs/toolkit";


const slice = createSlice({
    name: "api",
    initialState: {
        apis: [],
        loading: true,
        name: "",
        url: "",
        key: "",
        modal: false,
        apiId: ''
    },
    reducers: {
        claimData: (state, action) => {

        },
        claimDataSuccess: (state, action) => {
            state.apis = action.payload
            state.loading = false
        },
        toggle: (state, action) => {
            state.modal = action.payload
            if (!action.payload) {
                state.name = ''
                state.url = ''
                state.key = ''
            }
        },
        changeApiId: (state, action) => {
            state.apiId = action.payload
        },
        changeName: (state, action) => {
            state.name = action.payload
        },
        changeUrl: (state, action) => {
            state.url = action.payload
        },
        changeKey: (state, action) => {
            state.key = action.payload
        },
        saveApi: (state, action) => {

        },
        deleteApi: (state, action) => {

        }
    }
})

export const apiModel = slice.actions
export default slice.reducer