import {configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import {rootSaga} from "./rootSaga";
import apiReducer from "../pages/Api/redux/apiReducer";

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
    reducer: {
        apiReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(sagaMiddleware),
})

sagaMiddleware.run(rootSaga);
export default store