import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn, MDBIcon
} from 'mdb-react-ui-kit';
import {api_key_png, service_png, social_media_png} from "../../../accests/images";
import Footer from "../../ui/Footer";

function Index(props) {
    const {id} = useParams()
    const navigate = useNavigate()
    return (
        <>
            <div style={{padding:5}}>
                <h5 style={{cursor: "pointer"}} onClick={() => navigate(`/dashboard/`)}><MDBIcon fas
                                                                                                 icon="chevron-left"/> ORQAGA
                </h5>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                padding: 10,
                marginBottom: 100
            }}>
                <MDBCard style={{width: 300, marginBottom: 30}}>
                    <MDBCardImage width={300} height={300} src={api_key_png} position='top' alt='...'/>
                    <MDBCardBody>
                        <MDBCardTitle>🔑 API sozlamalari</MDBCardTitle>
                        <MDBCardText>
                            Shaxsiy api kalitlar qo'shish, o'chirish, taxrirlash bo'limi 📦
                        </MDBCardText>
                        <MDBBtn href={`/settings/api/${id}`}>🔑 API sozlamalariga o'tish</MDBBtn>
                    </MDBCardBody>
                </MDBCard>
                <MDBCard style={{width: 300, marginBottom: 30}}>
                    <MDBCardImage width={300} height={300} src={social_media_png} position='top' alt='...'/>
                    <MDBCardBody>
                        <MDBCardTitle>🎛 Tarmoq sozlamalari</MDBCardTitle>
                        <MDBCardText>
                            Ijtimoiy tarmoqlarni qo'shish, o'chirish, taxrirlash bo'limi 📁
                        </MDBCardText>
                        <MDBBtn href={`/settings/social/${id}`}>🎛 Tarmoq sozlamalariga o'tish</MDBBtn>
                    </MDBCardBody>
                </MDBCard>
                <MDBCard style={{width: 300, marginBottom: 30}}>
                    <MDBCardImage width={300} height={300} src={service_png} position='top' alt='...'/>
                    <MDBCardBody>
                        <MDBCardTitle>🛠 Xizmat sozlamalari</MDBCardTitle>
                        <MDBCardText>
                            Xizmatlarni qo'shish, o'chirish, taxrirlash bo'limi 🗳
                        </MDBCardText>
                        <MDBBtn href={`/settings/service/${id}`}>🛠 Xizmat sozlamalariga o'tish</MDBBtn>
                    </MDBCardBody>
                </MDBCard>
            </div>
            <Footer/>
        </>
    );
}

export default Index;