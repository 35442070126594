import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {MDBBtn, MDBIcon, MDBInput} from "mdb-react-ui-kit";
import instance from "../../utils/instance";
import {MDBTable, MDBTableHead, MDBTableBody} from 'mdb-react-ui-kit';
import {Pagination} from "@mui/material";
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import {MDBTextArea} from 'mdb-react-ui-kit';

import {ErrorNotify, SuccessNotify} from "../../alerts";

function Index(props) {
    const {id} = useParams()
    const navigate = useNavigate()
    const [payments, setPayments] = useState([])
    const [basicModal, setBasicModal] = useState(false);
    const [basicModalX, setBasicModalX] = useState(false);
    const [basicModalY, setBasicModalY] = useState(false);
    const [chatId, setChatId] = useState('')
    const [amount, setAmount] = useState('')
    const [type, setType] = useState('')
    const [bot, setBot] = useState(null)
    const [cardNumber, setCardNumber] = useState('')
    const [phone, setPhone] = useState('')
    const [amountT, setAmountT] = useState('')
    const [description, setDescription] = useState('')
    const [userInfo, setUserInfo] = useState(null)
    const toggleOpen = () => {
        setType('')
        setAmount('')
        setChatId('')
        setBasicModal(!basicModal)
    };
    const toggleOpenY = () => {
        setChatId('')
        setUserInfo(null)
        setBasicModalY(!basicModalY)
    };
    const toggleOpenX = () => {
        setAmountT('')
        setPhone('')
        setCardNumber('')
        setDescription('')
        setBasicModalX(!basicModalX)
    };

    async function getPayments(id, page) {
        let response = await instance(`/smm/payments?botId=${id}&page=${page}`);
        if (response.data.ok) {
            setPayments(response.data.data)
        }
    }

    async function getBotData(id) {
        let res = await instance(`/bot/${id}`, "GET");
        if (res.data.ok) {
            setBot(res.data.data)
        }
    }

    useEffect(() => {
        getPayments(id, 1)
        getBotData(id)
    }, [id]);

    async function userBalance(e) {
        e.preventDefault()
        let res = await instance("/smm/user/balance", "POST", {botId: id, chatId, type, amount});
        if (res.data.ok) {
            toggleOpen()
            SuccessNotify(res.data.message)
            getPayments(id, 1)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    async function ticketMoney(e) {
        e.preventDefault()
        let res = await instance("/bot/ticket", "POST", {id, amount: amountT, description, phone, cardNumber});
        if (res.data.ok) {
            SuccessNotify(res.data.message)
            getBotData(id)
            toggleOpenX()
        } else {
            ErrorNotify(res.data.message)
        }
    }

    async function showUserBalance(e) {
        e.preventDefault()
        let res = await instance(`/smm/show-balance?botId=${id}&chatId=${chatId}`, "GET");
        if (res.data.ok) {
            setUserInfo(res.data.data)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    return (
        <>
            <div style={{
                width: "100%",
                padding: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap"
            }}>
                <h5 style={{cursor: "pointer", marginBottom: 10}} onClick={() => navigate(`/settings/${id}`)}><MDBIcon
                    fas
                    icon="chevron-left"/> ORQAGA
                </h5>
                <MDBBtn style={{marginBottom: 10}} color='light' rippleColor='dark'>
                    BALANCE: {bot?.balance} UZS
                </MDBBtn>
                <MDBBtn onClick={toggleOpenX} style={{marginBottom: 10}} className='me-1' color='secondary'>
                    PUL YECHIB OLISH <MDBIcon fas icon="hand-holding-usd"/>
                </MDBBtn>
                <MDBBtn onClick={toggleOpenY} style={{marginBottom: 10}} className='me-1' color='secondary'>
                    FOYDALANUVCHI HISOBI <MDBIcon fas icon="dollar-sign"/>
                </MDBBtn>
                <MDBBtn onClick={toggleOpen} style={{marginBottom: 10}} className='me-1' color='secondary'>
                    FOYDALANUVCHIDAN BALANSINI BOSHQARISH <MDBIcon fas icon="dollar-sign"/>
                </MDBBtn>
            </div>
            <MDBTable>
                <MDBTableHead dark>
                    <tr>
                        <th scope='col'>TO'LOV ID</th>
                        <th scope='col'>USER ID</th>
                        <th scope='col'>MIQDORI</th>
                        <th scope='col'>OPERATOR</th>
                        <th scope='col'>VAQTI</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {payments?.list?.map((item) => <tr>
                        <td>{item?.id}</td>
                        <td>{item?.chatId}</td>
                        {
                            item?.type === "OUTLAY" ? <td style={{color: "red"}}>{item?.amount} UZS</td> :
                                <td style={{color: "green"}}>{item?.amount} UZS</td>
                        }
                        <td>{item.operator}</td>
                        <td>{new Date(item?.date).toLocaleDateString()} {new Date(item?.date).toLocaleTimeString()}</td>
                    </tr>)}
                </MDBTableBody>
            </MDBTable>
            <div style={{width: "100%", padding: 5, display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Pagination onChange={(event, page) => getPayments(id, page)} count={payments?.totalPages}
                            color="primary"/>
            </div>

            <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                <MDBModalDialog>
                    <form onSubmit={userBalance}>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>FOYDALANUVCHI BALANSINI BOSHQARISH</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBInput
                                    required={true} value={chatId} onChange={(e) => setChatId(e.target.value)}
                                    style={{marginBottom: 20}} label='FOYDALANUVCHI IDSI' id='form1' type='number'/>
                                <MDBInput value={amount} onChange={(e) => setAmount(e.target.value)}
                                          required={true}
                                          style={{marginBottom: 20}} label='PUL MIQDORI' id='form1' type='number'/>
                                <select required={true} value={type} onChange={(e) => setType(e.target.value)}
                                        style={{
                                            appearance: 'none',
                                            backgroundColor: 'transparent',
                                            border: '2px solid #bfbfbf',
                                            padding: '8px 12px',
                                            borderRadius: '4px',
                                            fontSize: '16px',
                                            color: 'blue',
                                            width: '100%',
                                            marginBottom: 15
                                        }}>
                                    <option value="" disabled={true} selected={true}>TANLANG</option>
                                    <option value="INCOME">PUL O'TKAZISH</option>
                                    <option value="OUTLAY">PUL YECHIB OLISH</option>
                                </select>
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={toggleOpen}>
                                    CHIQISH
                                </MDBBtn>
                                <MDBBtn type={'submit'}>SAQLASH</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </form>
                </MDBModalDialog>
            </MDBModal>
            <MDBModal open={basicModalX} setOpen={setBasicModalX} tabIndex='-1'>
                <MDBModalDialog>
                    <form onSubmit={ticketMoney}>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>PUL YECHIB OLISH UCHUN SO'ROV</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={toggleOpenX}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBInput value={cardNumber} onChange={(e) => setCardNumber(e.target.value)}
                                          required={true}
                                          style={{marginBottom: 20}} label='KARTA RAQAM' id='form1' type='number'/>
                                <MDBInput value={amountT} onChange={(e) => setAmountT(e.target.value)}
                                          required={true}
                                          style={{marginBottom: 20}} label='PUL MIQDORI' id='form1' type='number'/>
                                <MDBInput value={phone} onChange={(e) => setPhone(e.target.value)}
                                          required={true}
                                          style={{marginBottom: 20}} label='TELEFON RAQAM' id='form1' type='tel'/>
                                <MDBTextArea value={description} onChange={(e) => setDescription(e.target.value)}
                                             label='TAVSIF (MAJBURIY EMAS)' id='textAreaExample' rows={4}/>

                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={toggleOpenX}>
                                    CHIQISH
                                </MDBBtn>
                                <MDBBtn type={'submit'}>SAQLASH</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </form>
                </MDBModalDialog>
            </MDBModal>
            <MDBModal open={basicModalY} setOpen={setBasicModalY} tabIndex='-1'>
                <MDBModalDialog>
                    <form onSubmit={showUserBalance}>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>FOYDALANUCHI HISOBI</MDBModalTitle>
                                <MDBBtn className='btn-close' color='none' onClick={toggleOpenX}></MDBBtn>
                            </MDBModalHeader>
                            <MDBModalBody>
                                <MDBInput value={chatId} onChange={(e) => setChatId(e.target.value)}
                                          required={true}
                                          style={{marginBottom: 20}} label='FOYDALANUVCHI IDSI' id='form1'
                                          type='number'/>
                                {userInfo !== null ? <b>Foydalanuvchi hisobi: {userInfo} UZS</b> : null}
                            </MDBModalBody>

                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={toggleOpenY}>
                                    CHIQISH
                                </MDBBtn>
                                <MDBBtn type={'submit'}>SAQLASH</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </form>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

export default Index;