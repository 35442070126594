import React from 'react';
import {useParams} from "react-router-dom";
import AddApi from "./AddApi";
import ApiList from "./ApiList";

function Index(props) {
    const {id} = useParams()
    return (
        <div style={{width: "100%", height: "100vh", padding: 10, background: "white"}}>
            <AddApi/>
            <ApiList/>
        </div>
    );
}

export default Index;