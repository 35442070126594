import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {apiModel} from "../redux/apiReducer";
import {useParams} from "react-router-dom";
import {MDBAccordion, MDBAccordionItem, MDBBtn, MDBBtnGroup, MDBIcon, MDBTypography} from 'mdb-react-ui-kit';
import {SuccessNotify} from "../../../alerts";

function Index(props) {
    const {apis} = props.apiReducer
    const {id} = useParams()
    useEffect(() => {
        props.claimData(id)
    }, []);

    function editApi(item) {
        props.changeApiId(item.id)
        props.toggle(true)
        props.changeName(item.name)
        props.changeUrl(item.url)
        SuccessNotify("MAXFIYLIK SABABLI API KALIT KO'RSATILMAYDI!")
    }

    return (
        <div>
            <MDBAccordion flush initialActive={1}>
                {apis?.map((item, index) => {
                    return <MDBAccordion flus>
                        <MDBAccordionItem collapseId={item?.id} headerTitle={`#${index + 1} ${item?.name}`}>
                            <MDBTypography tag='h6'>NOMI: {item?.name}</MDBTypography>
                            <MDBTypography tag='h6'>HAVOLA: {item?.url}</MDBTypography>
                            <MDBTypography tag='h6'>BALANCE: {item?.balance} {item?.currency}</MDBTypography>
                            <MDBBtnGroup style={{marginTop: 30}} shadow='0'>
                                <MDBBtn onClick={() => props.deleteApi({id: item?.id, bot: id})} color='danger'>
                                    <MDBIcon fas icon="trash"/>
                                </MDBBtn>
                                <MDBBtn onClick={() => editApi(item)} color='success'>
                                    <MDBIcon fas icon="pencil-alt"/>
                                </MDBBtn>
                            </MDBBtnGroup>
                        </MDBAccordionItem>
                    </MDBAccordion>
                })}
            </MDBAccordion>
        </div>
    );
}

export default connect((state) => state, apiModel)(Index)
