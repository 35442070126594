import {put, takeEvery, select} from "redux-saga/effects"
import {apiModel} from "./apiReducer";
import {ErrorNotify, SuccessNotify} from "../../../alerts";
import instance from "../../../utils/instance";


function* claimData(id) {
    const res = yield instance(`/smm/api-key/${id.payload}`, "GET");
    if (res.data.ok) {
        yield put(apiModel.claimDataSuccess(res.data.data))
    } else {
        ErrorNotify(res.data.message)
    }
}

function* saveApi(id) {
    const {name, url, key, apiId} = yield select((state) => state.apiReducer);
    if (apiId === '') {
        let res = yield instance(`/smm/add/api-key`, "POST", {name, url, key, botId: id.payload});
        if (res.data.ok) {
            yield put(apiModel.toggle(false))
            SuccessNotify(res.data.message)
            yield put(apiModel.claimData(id.payload))
        } else {
            ErrorNotify(res.data.message)
        }
    } else {
        let res = yield instance(`/smm/api-key/${apiId}`, "PUT", {name, url, key});
        if (res.data.ok) {
            yield put(apiModel.toggle(false))
            SuccessNotify(res.data.message)
            yield put(apiModel.claimData(id.payload))

        } else {
            ErrorNotify(res.data.message)
        }
    }
}

function* deleteApi(action) {
    let res = yield instance(`/smm/api-key/${action.payload.id}`, "DELETE");
    if (res.data.ok) {
        SuccessNotify(res.data.message)
        yield put(apiModel.claimData(action.payload.bot))
    } else {
        ErrorNotify(res.data.message)
    }
}

export function* apiSaga() {
    yield  takeEvery(apiModel.claimData.type, claimData)
    yield  takeEvery(apiModel.saveApi.type, saveApi)
    yield  takeEvery(apiModel.deleteApi.type, deleteApi)
}