import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {MDBBtn, MDBBtnGroup, MDBIcon, MDBTypography} from "mdb-react-ui-kit";
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import {MDBInput} from 'mdb-react-ui-kit';
import {MDBTextArea} from 'mdb-react-ui-kit';
import instance from "../../utils/instance";
import {ErrorNotify, SuccessNotify} from "../../alerts";
import {MDBAccordion, MDBAccordionItem} from 'mdb-react-ui-kit';

function Index(props) {
    const navigate = useNavigate()
    const {id} = useParams()
    const [basicModal, setBasicModal] = useState(false);
    const [services, setServices] = useState([])
    const [apis, setApis] = useState([])
    const [categories, setCategories] = useState([])
    const [name, setName] = useState('')
    const [serviceId, setServiceId] = useState('')
    const [min, setMin] = useState('')
    const [max, setMax] = useState('')
    const [perPrice, setPerPrice] = useState('')
    const [desc, setDesc] = useState('')
    const [selectedApi, setSelectedApi] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('')
    const [currentService, setCurrentService] = useState(null)

    async function getServicesList(id) {
        let res = await instance(`/smm/service/${id}`);
        if (res.data.ok) {
            setServices(res.data.data)
        }
    }

    async function getApiList(id) {
        let res = await instance(`/smm/api-key/${id}`);
        if (res.data.ok) {
            setApis(res.data.data)
        }
    }

    async function getCategoryList(id) {
        let res = await instance(`/smm/social/${id}`);
        if (res.data.ok) {
            setCategories(res.data.data)
        }
    }

    useEffect(() => {
        getServicesList(id);
        getCategoryList(id)
        getApiList(id)
    }, [id]);
    const toggleOpen = () => {
        setBasicModal(!basicModal);
        setName('')
        setServiceId('')
        setMin('')
        setMax('')
        setPerPrice('')
        setDesc('')
        setSelectedCategory('')
        setSelectedApi('')
    }

    async function saveService(e) {
        e.preventDefault()
        const data = {
            name,
            serviceId,
            minQuantity: min,
            maxQuantity: max,
            perPrice,
            description: desc,
            categoryId: selectedCategory,
            apiId: selectedApi,
            botId: id
        }
        if (currentService === null) {
            let res = await instance("/smm/service", "POST", data);
            if (res.data.ok) {
                SuccessNotify(res.data.message)
                getServicesList(id)
                toggleOpen()
            } else {
                ErrorNotify(res.data.message)
            }
        } else {
            let res = await instance(`/smm/service/${currentService.id}`, "PUT", data);
            if (res.data.ok) {
                setCurrentService(null)
                SuccessNotify(res.data.message)
                getServicesList(id)
                toggleOpen()
            } else {
                ErrorNotify(res.data.message)
            }
        }
    }

    async function deleteService(s_id) {
        let res = await instance(`/smm/service/${s_id}`, "DELETE");
        if (res.data.ok) {
            SuccessNotify(res.data.message)
            getServicesList(id)
        } else {
            ErrorNotify(res.data.message)
        }
    }

    function editSocial(item) {
        setCurrentService(item)
        toggleOpen()
        setName(item.name)
        setServiceId(item.serviceId)
        setMin(item.minQuantity)
        setMax(item.maxQuantity)
        setPerPrice(item.perPrice)
        setDesc(item.description)
        setSelectedApi(item.api.id)
        setSelectedCategory(item.category.id)
    }

    return (
        <>
            <div style={{
                width: "100%",
                padding: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <h5 style={{cursor: "pointer"}} onClick={() => navigate(`/settings/${id}`)}><MDBIcon fas
                                                                                                     icon="chevron-left"/> ORQAGA
                </h5>                <MDBBtn onClick={toggleOpen} className='me-1' color='secondary'>
                XIZMAT QO'SHISH <MDBIcon fas icon="folder-plus"/>
            </MDBBtn>
            </div>
            <MDBAccordion flush>
                {
                    services?.map((item, index) => {
                        return <MDBAccordionItem collapseId={item?.id} headerTitle={item?.name}>
                            <MDBTypography tag='h6'>NOMI: {item?.name}</MDBTypography>
                            <MDBTypography tag='h6'>SERVICE RAQAMI: {item?.serviceId}</MDBTypography>
                            <MDBTypography tag='h6'>MINIMAL BUYURTMA SONI: {item?.minQuantity}</MDBTypography>
                            <MDBTypography tag='h6'>MAXIMAL BUYURTMA SONI: {item?.maxQuantity}</MDBTypography>
                            <MDBTypography tag='h6'>NARXI 1000TA BUYURTMA SONIGA: {item?.perPrice}</MDBTypography>
                            <MDBTypography tag='h6'>TAVSIF: {item?.description}</MDBTypography>
                            <MDBTypography tag='h6'>TARMOQ: {item?.category?.name}</MDBTypography>
                            <MDBTypography tag='h6'>API: {item?.api?.name}</MDBTypography>
                            <MDBBtnGroup shadow='0'>
                                <MDBBtn onClick={() => deleteService(item.id)} color='danger'>
                                    <MDBIcon fas icon="trash"/>
                                </MDBBtn>
                                <MDBBtn onClick={() => editSocial(item)} color='success'>
                                    <MDBIcon fas icon="pencil-alt"/>
                                </MDBBtn>
                            </MDBBtnGroup>
                        </MDBAccordionItem>
                    })
                }
            </MDBAccordion>

            <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>BENEFITGRAM</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                        </MDBModalHeader>
                        <form onSubmit={saveService}>
                            <MDBModalBody>
                                <MDBInput required={true} value={name} onChange={(e) => setName(e.target.value)}
                                          style={{marginBottom: 15}}
                                          label='XIZMAT NOMI' id='form1' type='text'/>
                                <MDBInput required={true} value={serviceId}
                                          onChange={(e) => setServiceId(e.target.value)}
                                          style={{marginBottom: 15}} label='XIZMAT RAQAMI' id='form1' type='number'/>
                                <MDBInput required={true} value={min} onChange={(e) => setMin(e.target.value)}
                                          style={{marginBottom: 15}}
                                          label='MINIMAL BUYURTMA SONI' id='form1'
                                          type='number'/>
                                <MDBInput required={true} value={max} onChange={(e) => setMax(e.target.value)}
                                          style={{marginBottom: 15}}
                                          label='MAXIMAL BUYURTMA SONI' id='form1'
                                          type='number'/>
                                <MDBInput required={true} value={perPrice} onChange={(e) => setPerPrice(e.target.value)}
                                          style={{marginBottom: 15}} label='NARXI 1000TA BUYURTMA SONIGA' id='form1'
                                          type='number'/>
                                <MDBTextArea required={true} value={desc} onChange={(e) => setDesc(e.target.value)}
                                             style={{marginBottom: 15}} label='FOYDALANUVCHI UCHUN TAVSIF'
                                             id='textAreaExample' rows={4}/>
                                <select required={true} value={selectedCategory}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                        style={{
                                            appearance: 'none',
                                            backgroundColor: 'transparent',
                                            border: '2px solid #bfbfbf',
                                            padding: '8px 12px',
                                            borderRadius: '4px',
                                            fontSize: '16px',
                                            color: 'blue',
                                            width: '100%',
                                            marginBottom: 15
                                        }}>
                                    <option value="" disabled={true} selected={true}>TARMOQNI TANLANG</option>
                                    {
                                        categories?.map((item) => <option value={item?.id}
                                                                          key={item?.id}>{item?.name}</option>)
                                    }
                                </select>
                                <select required={true} value={selectedApi}
                                        onChange={(e) => setSelectedApi(e.target.value)}
                                        style={{
                                            appearance: 'none',
                                            backgroundColor: 'transparent',
                                            border: '2px solid #bfbfbf',
                                            padding: '8px 12px',
                                            borderRadius: '4px',
                                            fontSize: '16px',
                                            color: 'blue',
                                            width: '100%',
                                            marginBottom: 15
                                        }}>
                                    <option value="" disabled={true} selected={true}>APINI TANLANG</option>
                                    {
                                        apis?.map((item) => <option value={item?.id}
                                                                    key={item?.id}>{item?.name}</option>)
                                    }
                                </select>
                            </MDBModalBody>


                            <MDBModalFooter>
                                <MDBBtn color='secondary' onClick={toggleOpen}>
                                    CHIQISH
                                </MDBBtn>
                                <MDBBtn type={"submit"}>SAQLASH</MDBBtn>
                            </MDBModalFooter>
                        </form>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

export default Index;
