import {all, fork} from "redux-saga/effects"
import {apiSaga} from "../pages/Api/redux/apiSaga";

export function* rootSaga() {
    try {
        yield all([
            fork(apiSaga)
        ])
    } catch (error) {
        console.log(error)
    }
}