import React, {useEffect, useState} from 'react';
import {MDBBtn, MDBIcon} from "mdb-react-ui-kit";
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import {MDBInput} from 'mdb-react-ui-kit';
import {apiModel} from "../redux/apiReducer";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";


function Index(props) {
    const navigate = useNavigate();
    const data = props.apiReducer
    const {id} = useParams()
    const [modal, setModal] = useState(false)
    useEffect(() => {
        setModal(data.modal)
    }, [data.modal]);
    useEffect(() => {
        props.toggle(modal)
    }, [modal]);
    return (
        <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <h5 style={{cursor: "pointer"}} onClick={() => navigate(`/settings/${id}`)}><MDBIcon fas
                                                                                                 icon="chevron-left"/> ORQAGA
            </h5>
            <MDBBtn onClick={() => props.toggle(true)} className='me-1' color='secondary'>
                API QO'SHISH <MDBIcon fas icon="folder-plus"/>
            </MDBBtn>
            <MDBModal open={modal} setOpen={setModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>BENEFITGRAM</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={() => setModal(!modal)}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <MDBInput value={data.name} onChange={(e) => props.changeName(e.target.value)}
                                      style={{marginBottom: 20}} label='API NOMI' id='form1' type='text'/>
                            <MDBInput value={data.url} onChange={(e) => props.changeUrl(e.target.value)}
                                      style={{marginBottom: 20}} label='API HAVOLA' id='form1' type='text'/>
                            <MDBInput value={data.key} onChange={(e) => props.changeKey(e.target.value)}
                                      style={{marginBottom: 20}} label='API KALIT' id='form1' type='text'/>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={() => setModal(!modal)}>
                                CHIQISH
                            </MDBBtn>
                            <MDBBtn onClick={() => props.saveApi(id)}>SAQLASH <MDBIcon fas icon="save"/></MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </div>
    );
}

export default connect((state) => state, apiModel)(Index)
